import React from 'react'
import styled from 'styled-components';
import { Link } from '../../components/Link/Link';
import { Text } from '../../components/Text/Text';
import { color, typography } from '../../shared/style';
// import location_icon from '../../images/location_icon.svg';
// import phone_icon from '../../images/phone_icon.svg';
// import email_icon from '../../images/email_icon.svg';
import facebook_icon from '../../images/facebook-icon.svg';
import instagram_icon from '../../images/instagram-icon.svg';
import linkedin_icon from '../../images/linkedin-icon.svg';
import tiktok_icon from '../../images/tik-tok-01.svg';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from "../../components/RichtextHelper/CustomText"
import PropTypes from "prop-types";
import { Button } from '../../components/Button/Button';
import { redirectPage } from '../../hooks/redirect';

export const Address = ({ data }) => {
  if (!data?.primary) return null;
  const { primary } = data;
  return (
    <div id="whereAreWe">
      <WrapperSection>
        <Vehical>
          <Title
            tag="h3"
            font={typography.type.code}
            weight={typography.weight.regular1}
            lineHeight={90}
            color={color.primary.white}
            capital="uppercase"
          >
            <RichText
              render={primary?.title1?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <AddressInfo>
            <WrapperCity>
              <Info>
                <City
                  tag="h5"
                  weight={typography.weight.regular1}
                  font={typography.type.code}
                  color={color.primary.white}
                  capital="uppercase"
                  lineHeight={36}
                >
                  {data?.city}
                </City>
                <Country
                  weight={typography.weight.regular}
                  font={typography.type.primary}
                  color={color.primary.white}
                  lineHeight={22}
                  size={14}
                >
                  {data?.country}
                </Country>
                <AddressDetail isMobile={true}>
                  <Map>
                    <RichText
                      render={primary?.map?.raw}
                      htmlSerializer={htmlSerializer}
                    />
                  </Map>
                </AddressDetail>
              </Info>
            </WrapperCity>
            <WrapperAddressContact>
              <SmallSection>
                {/* <Icon src={location_icon}></Icon> */}
                <Label>{primary?.address?.text}</Label>
              </SmallSection>
              <SmallSection phone={true}>
                {/* <Icon src={phone_icon}></Icon> */}

                <LinkPhone type="tel">{primary?.phone_number?.text}</LinkPhone>
              </SmallSection>
              <SmallSection>
                {/* <Icon src={email_icon}></Icon> */}
                <LinkMail type="mail">{primary?.email?.text}</LinkMail>
              </SmallSection>
              <Social>
                {primary?.facebook?.url && (
                  <Link blank to={primary?.facebook?.url}>
                    <IconContact src={facebook_icon}></IconContact>
                  </Link>
                )}
                {primary?.instagram?.url && (
                  <Link blank to={primary?.instagram?.url}>
                    <IconContact src={instagram_icon}></IconContact>
                  </Link>
                )}
                {primary?.linkedin?.url && (
                  <Link to={primary?.linkedin?.url} blank>
                    <IconContact src={linkedin_icon}></IconContact>
                  </Link>
                )}
                {primary?.tiktok?.url && (
                  <Link to={primary?.tiktok?.url} blank>
                    <IconContact src={tiktok_icon}></IconContact>
                  </Link>
                )}
              </Social>
            </WrapperAddressContact>
            {primary?.button_label?.text && (
              <div>
                <ButtonExtend
                  type="tertiary"
                  onClick={() =>
                    redirectPage({
                      url: primary?.button_link?.url,
                      type: primary?.button_link?.type,
                      link_type: primary?.button_link?.link_type
                    })
                  }
                >{primary?.button_label?.text}</ButtonExtend>
              </div>
            )}
          </AddressInfo>
        </Vehical>
        <AddressDetail>
          <Map>
            <RichText
              render={primary?.map?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Map>
        </AddressDetail>
      </WrapperSection>
    </div>
  );
};

const ButtonExtend = styled(Button)`
  margin-top: 20px;
  width: 100%;
  max-width: fit-content;
`;

const WrapperSection = styled.div`
  background-color: ${color.primary.blue};
  display: flex;
  justify-content: space-between;
  padding: 100px 132px;
  @media (max-width: 1000px) and (min-width: 800px) {
    flex-direction: column;
    padding: 40px;
  }
  @media (max-width: 799px) and (min-width: 651px) {
    flex-direction: column;
    padding: 40px;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    padding: 20px;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
  }
`;

const Vehical = styled.div`
  width: 40%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const AddressDetail = styled.div`
  width: 55%;
  @media (max-width: 1000px) {
    width: 100%;
  }
  display: ${(props) => (props.isMobile ? "none" : "block")};
  @media (max-width: 650px) {
    display: ${(props) => (props.isMobile ? "block" : "none")};
  }
`;

const Title = styled(Text)`
  @media (max-width: 650px) {
    line-height: 62px !important;
    margin-bottom: 30px;
  }
`;

const AddressInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  @media (max-width: 1000px) and (min-width: 651px) {
    flex-direction: row;
  }
  @media (max-width: 650px) {
    margin-top: 23px;
  }
`;

const Map = styled.div`
  width: 100%;
  div iframe {
    width: 100%;
    height: 450px;
  }
  @media (max-width: 649px) {
    margin-top: 20px;
  }
`;

const WrapperCity = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 50px; */
  margin-bottom: 40px;
  @media (max-width: 1000px) and (min-width: 801px) {
    margin-top: 30px;
    margin-right: 20px;
  }
  @media (max-width: 800px) and (min-width: 750px) {
    width: 25%;
    margin-top: 30px;
    margin-right: 20px;
  }
  @media (max-width: 749px) and (min-width: 651px) {
    width: 40%;
    margin-top: 30px;
    margin-right: 20px;
  }
  @media (max-width: 650px) {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;
const WrapperAddressContact = styled.div`
  @media (max-width: 1000px) and (min-width: 651px) {
    margin-top: 30px;
    margin-right: 20px;
  }
`;
const City = styled(Text)`
  @media (max-width: 650px) {
    line-height: 36px !important;
    font-size: 36px !important;
  }
`;
const Country = styled(Text)``;
const Social = styled.div`
  display: flex;
  margin-top: 20px;
`;
const SmallSection = styled.div`
  display: flex;
  align-items: baseline;
  margin: ${(props) => props.phone && "10px 0"};
`;
// const Icon = styled.img`
//   margin-right: 13px;
// `
const Label = styled(Text)`
  font-family: ${typography.type.primary};
  font-size: ${typography.size.s3};
  font-weight: ${typography.weight.bold};
  color: ${color.primary.white};
  line-height: 26px;
`;
const LinkPhone = styled(Link)`
  color: white;
  font-weight: ${typography.weight.bold};
`;
const LinkMail = styled(Link)`
  color: white;
  font-weight: ${typography.weight.bold};
  position: relative;
  &:after {
    content: "";
    border-bottom: 1px solid white;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
`
const IconContact = styled.img`
  margin-right: 20px;
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  @media (max-width: 650px) {
  }
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
`

Address.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      address: PropTypes.shape({
        text: PropTypes.string,
      }),
      city: PropTypes.shape({
        text: PropTypes.string,
      }),
      country: PropTypes.shape({
        text: PropTypes.string,
      }),
      email: PropTypes.shape({
        text: PropTypes.string,
      }),
      phone_number: PropTypes.shape({
        text: PropTypes.string,
      }),
      title: PropTypes.shape({
        text: PropTypes.string,
      }),
      map: PropTypes.shape({
        text: PropTypes.string,
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string
          })
        )
      }),
      facebook: PropTypes.shape({
        url: PropTypes.string,
        link_type: PropTypes.string
      }),
      instagram: PropTypes.shape({
        url: PropTypes.string,
        link_type: PropTypes.string
      }),
      linkedin: PropTypes.shape({
        url: PropTypes.string,
        link_type: PropTypes.string
      }),
      twitter: PropTypes.shape({
        url: PropTypes.string,
        link_type: PropTypes.string
      }),
    }),
  })
};

Address.defaultProps = {};
