import React, { useEffect, useState } from 'react'
// import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import styled from 'styled-components';

export const DateRangePickerComponent = ({ selectedDate, setSelectedDate }) => {

  const [direction, setDirection] = useState('horizontal');
  const [month, setMonth] = useState(2);

  const isBrowser = () => typeof window !== "undefined";
  useEffect(() => {
    if (isBrowser() && window.innerWidth < 800) {
      setDirection('vertical');
      setMonth(1)
    }
  }, [])
  return (
    <Wrapper>
      <DateRangePicker
        onChange={item => {
          // setState([item.selection])
          setSelectedDate([item.selection])
        }}
        staticRanges={[]}
        inputRanges={[]}
        months={month}
        ranges={selectedDate}
        direction={direction}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`
margin-bottom: 40px;
  .rdrDefinedRangesWrapper {
    display: none;
  }
  /* .rdrDateDisplayWrapper {
    width: 80%;
    @media (max-width: 800px) {
      width: 69%;
    }
  } */
  .rdrDateInput {
    &:first-child {
      font-size: 18px;
      &::before {
        content: "Check-in";
        position: absolute;
        top: -40px;
        text-transform: uppercase;
        color: gray
      }
      @media (max-width: 800px) {
        font-size: 16px;
      }
    }
    &:last-child {
      font-size: 18px;
      &::before {
        content: "Check-out";
        position: absolute;
        top: -40px;
        text-transform: uppercase;
        color: gray
      }
      @media (max-width: 800px) {
        font-size: 16px;
      }
    }
  }
`