import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text"
import { color, typography } from '../../shared/style';
import PropTypes from "prop-types";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
export const About = ({ data }) => {

  if(!data?.primary || !data?.primary) return null;
  const {title, description} = data?.primary;
  const {items} = data;
  return (
    <div id="about">
      <Wrapper>
        <WrapperInfo>
          <Info>
            {/* About */}
            <WrapperAbout>
              <TitleAbout
                tag="h4"
                capital="uppercase"
                weight={typography.weight.regular1}
                color={color.netraul.black100}
                font="Alternate Gothic No1 D"
              >
                {title}
              </TitleAbout>
              <Content
                tag="p"
                font="Oxygen"
                color={color.netraul.black80}
                size={typography.size.s3}
                lineHeight={26}
              >
                <RichText
                  render={description}
                  htmlSerializer={htmlSerializer}
                />
              </Content>
            </WrapperAbout>
            {/* Amennities */}
            <Amennities>
              <TitleAmennities
                tag="h4"
                font="Alternate Gothic No1 D"
                weight={typography.weight.regular1}
                color={color.primary.green40}
                capital="uppercase"
                lineHeight={56}
              >
                <RichText
                  render={items?.primary?.title1?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </TitleAmennities>
              <ListItem>
                {items?.items &&
                  items?.items?.map((item, i) => (
                    <Item key={i}>
                      <Img src={item.icon.url} />
                      {item?.label}
                    </Item>
                  ))}
              </ListItem>
            </Amennities>
          </Info>
        </WrapperInfo>
      </Wrapper>
    </div>
  );
};
const Img = styled.img`
  margin-right: 20px;
  width: 20px;
`;
const TitleAmennities = styled(Text)`
  letter-spacing: -0.015em;
  line-height: 56px;
  @media (max-width: 650px) {
    
  line-height: 36px;
  }
`;
const TitleAbout = styled(Text)`
  padding: 28px 0;
  letter-spacing: -0.015em;
  line-height: 56px;
  @media (max-width: 650px) {
    line-height: 36px;
  }
`;
const Content = styled(Text)`
  margin-top: 30px;
  letter-spacing: 0.015em;
`;
const Info = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
const WrapperInfo = styled.div`
  display: flex;
  margin-bottom: 100px;
  @media (max-width: 1000px) {
    margin-bottom: 0;
  }
`;

const Item = styled.div`
  width: 50%;
  margin-bottom: 23px;
  line-height: 26px !important;
  display: flex;
  align-items: center;
  color: ${color.netraul.black100};
  @media (max-width: 1400px) {
    align-items: flex-start;
  }
`;
const WrapperAbout = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const ListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 30px;
  align-content: flex-start;
`;
const Amennities = styled.div`
  width: 50%;
  margin-left: 100px;
  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 40px;
  }
`;
const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 20px;
  @media (max-width: 1300px) {
    padding: 0 40px;
    box-sizing: border-box;
  }
  @media (max-width: 1000px) {
    margin-top: 0;
    padding-top: 0;
  }
  @media (max-width: 650px) {
    padding: 20px;
  }
`;
About.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.shape({
          url: PropTypes.string,
        }),
        label: PropTypes.string,
      })
    ),
    primary: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string
      }),
      description: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};