import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Link } from "../../components/Link/Link";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import image1 from "../../images/Academy-become-startup.png";
import { color, typography } from "../../shared/style";

const Fundamentals = (dataFundamental) => {
  const data = dataFundamental.dataFundamental;
  if (!dataFundamental) return null;

  const enrollLink = data.link?.url;

  return (
    <WrapperSection>
      <BodyContainer>
        <BodyInfor>
          <InfoHeader
            font={typography.type.secondary}
            color={color.primary.blue}
            weight={typography.weight.regular1}
            align="left"
            size={typography.size.l3}
            lineHeight={56}
            capital="uppercase"
          >
            <RichText
              render={data?.title1?.raw}
              htmlSerializer={htmlSerializer}
            />
          </InfoHeader>
          <InforDate
            font={typography.type.primary}
            color={color.netraul.black80}
            weight={typography.weight.regular}
            align="left"
            size={typography.size.s4}
            lineHeight={28}
          >
            Start date:{" "}
            <DateTime
              font={typography.type.primary}
              color={color.netraul.black100}
              weight={typography.weight.bold}
              align="center"
              size={typography.size.m1}
              lineHeight={28}
            >
              {data?.start_date}
            </DateTime>
          </InforDate>
          <Mobile>
            <BodyImage>
              <Image src={data?.image?.url}></Image>
            </BodyImage>
          </Mobile>
          <InforDescription>
            <Desktop>
              <Description
                font={typography.type.primary}
                color={color.netraul.black100}
                weight={typography.weight.regular}
                align="left"
                size={typography.size.s3}
                lineHeight={26}
              >
                <RichText
                  render={data?.content?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Description>
              <LinkEnroll
                color={color.primary.blue}
                lineHeight={26}
                weight={typography.weight.bold}
                font={typography.type.code}
                textDecoration="none"
                to={enrollLink}
              >
                {data?.link_label?.text}
              </LinkEnroll>
            </Desktop>
            <Mobile>
              <Description
                font={typography.type.primary}
                color={color.netraul.black100}
                weight={typography.weight.regular}
                align="left"
                size={typography.size.s3}
                lineHeight={26}
              >
                {data?.content?.raw[0]?.text}
              </Description>
              <LinkSeeMore to={enrollLink}>
                {data?.link_label?.text}
              </LinkSeeMore>
            </Mobile>
          </InforDescription>
        </BodyInfor>

        <Desktop>
          <BodyImage>
            <Image src={data?.image?.url}></Image>
            {/* <Image src={data.image} alt={data.title}></Image> */}
          </BodyImage>
        </Desktop>
      </BodyContainer>
    </WrapperSection>
  );
};
const LinkEnroll = styled(Link)`
  text-decoration: underline ${color.primary.blue};
  text-underline-offset: 5px;
  display: inline-block;
  margin-top: 41px;
`;

const Desktop = styled.div`
  /* width: 50%; */
  @media (max-width: 992px) {
    display: none;
  }
`;
const Mobile = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  /* margin-top: 150px; */
  padding: 0 131px;
  @media (max-width: 1200px) {
    /* margin-top: 80px; */
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    /* margin-top: 80px; */
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    /* margin-top: 80px; */
    padding: 0 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TitleHeader = styled(Text)`
  display: block;
  width: 940px;
  @media (max-width: 1200px) {
    font-size: 62px !important;
    line-height: 62px;
    width: auto;
  }
  @media (max-width: 650px) {
    text-align: center;
  }
`;
const Author = styled(Text)`
  letter-spacing: 0.015em;
  margin-bottom: 20px;
`;
const DescriptionHeader = styled(Text)`
  margin-top: 32px;
  display: block;
  max-width: 694px;
  white-space: unset;
  @media (max-width: 1200px) {
    margin-top: 24px;
  }
`;
const BodyContainer = styled.div`
  display: flex;
  margin-bottom: 60px;
  @media (max-width: 1200px) {
    margin-bottom: 60px;
  }
  @media (max-width: 992px) {
    margin-bottom: 40px;
  }
  @media (max-width: 650px) {
    margin-bottom: 40px;
  }
`;
const BodyInfor = styled.div`
  width: 50%;
  @media (max-width: 1200px) {
    width: 60%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 650px) {
  }
`;
const InfoHeader = styled(Text)`
  letter-spacing: -0.015em;
  max-width: 500px;
  display: block;
  padding-right: 30px;
  box-sizing: border-box;
  @media (max-width: 650px) {
    font-size: 36px !important;
    line-height: 36px;
  }
`;
const InforDate = styled(Text)`
  font-family: ${typography.type.primary};
  display: flex;
  align-items: center;
  margin-top: 24px;
  @media (max-width: 1200px) {
    margin-top: 14px;
  }
`;
const DateTime = styled(Text)`
  margin-left: 8px;
`;
const InforDescription = styled.div`
  padding-right: 123px;
  @media (max-width: 1200px) {
    padding-right: 60px;
    margin-top: 30px;
  }
  @media (max-width: 992px) {
    padding-right: 30px;
    margin-top: 30px;
  }
  @media (max-width: 650px) {
    padding-right: 0;
    margin-top: 30px;
  }
`;
const Description = styled(Text)`
  margin-top: 32px;
  display: block;

  @media (max-width: 1200px) {
    margin-top: 14px;
    text-align: start;
  }
`;
const LinkSeeMore = styled(Link)`
  display: inline-block;
  color: ${color.primary.blue};
  border-bottom: 1px solid ${color.primary.blue};
  font-weight: bold;
  margin-top: 32px;
`;
const BodyImage = styled.div`
  width: 100%;
  max-height: 540px;

  @media (max-width: 992px) {
    margin-top: 30px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 540px;
  object-fit: cover;
  @media (max-width: 920px) {
    width: 100%;
    height: 300px;
  }
  @media (max-width: 650px) {
  }
`;

Fundamentals.propTypes = {
};

Fundamentals.defaultProps = {
};

export default Fundamentals;
