import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Header } from '../containers/HeaderContainer/index'
import FooterContainer from '../containers/FooterContainer'
import useApp, { withAppContext } from '../contexts/context';
import SEO from '../components/SEO'
import { useSiteMetadata } from '../hooks/useQuerydata';
import Layout from '../components/Layout';
import { Sidebar } from '../components/Sidebar';
import { SpaceContainer } from '../containers/SpaceConntainer/SpaceContainer';
import { graphql } from 'gatsby';

const LocationSpace = ({ pageContext, data, ...props }) => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const theme = {
    bgColor: 'white',
  }
  if (!data) return null
  const space = data.prismicLocationSpace.data
  const upcomingEvent = data.allEventbriteEvent.edges
  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer
            data={allPrismicFooter?.nodes[0]?.data}
          />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={space?.body8} />
        }
      >
        <SpaceContainer data={space} upcomingEvent={upcomingEvent} />
      </Layout>
    </ThemeProvider>
  )
}

export default withAppContext(LocationSpace);

export const query = graphql`
      query LocationSpaceQuery($id: String) {
        prismicLocationSpace(id: {eq: $id}) {
          data {
            city {
              text
            }
            country {
              text
            }
            description {
              text
              raw
            }
            geo_location {
              latitude
              longitude
            }
            event_button_label {
              text
            }
            event_button_link {
              url
              link_type
              type
            }
            tags {
              tag_name
            }
            thumbnail {
              url
            }
            title {
              text
              raw
            }
            gallery {
              image {
                url
              }
            }
            body9 {
              ... on PrismicLocationSpaceBody9Team {
                items {
                  member {
                    document {
                      ... on PrismicTeamMembers {
                        data {
                          avatar {
                            url
                          }
                          contacts {
                            contact_link {
                              url
                              link_type
                              type
                            }
                            contact_label
                          }
                          name {
                            text
                          }
                          last_name {
                            text
                          }
                          position {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                primary {
                  title1 {
                    text
                    raw
                    html
                  }
                }
              }
            }
            body1 {
              ... on PrismicLocationSpaceBody1Offer {
                items {
                  title
                  image {
                    url
                  }
                  start_date_of_week
                  end_date_of_week
                  start_time
                  end_time
                  content {
                    text
                    raw
                    html
                  }
                  button_link {
                    url
                    link_type
                    type
                  }
                  button_label {
                    text
                  }
                }
                primary {
                  title {
                    text
                    raw
                    html
                  }
                  description {
                    text
                    raw
                    html
                  }
                }
              }
            }
            body2 {
              ... on PrismicLocationSpaceBody2Amennities {
                primary {
                  title1 {
                    text
                    raw
                    html
                  }
                }
                items {
                  icon {
                    url
                  }
                  label
                }
              }
            }
            body3 {
              ... on PrismicLocationSpaceBody3MiniGuide {
                primary {
                  title1 {
                    html
                    text
                    raw
                  }
                  image {
                    url
                  }
                  description1 {
                    html
                    text
                    raw
                  }
                }
                items {
                  guide {
                    text
                  }
                }
              }
            }
            fundamentals {
              content {
                html
                raw
                text
              }
              image {
                alt
                url
              }
              link {
                url
                type
                link_type
              }
              link_label {
                html
                raw
                text
              }
              start_date
              title1 {
                html
                raw
                text
              }
            }
            body4 {
              ... on PrismicLocationSpaceBody4Reviews {
                primary {
                  title1 {
                    html
                    text
                    raw
                  }
                }
                items {
                  avatar {
                    url
                  }
                  name {
                    text
                  }
                  position {
                    text
                  }
                  comment {
                    html
                    text
                    raw
                  }
                  rating
                }
              }
            }
            body5 {
              ... on PrismicLocationSpaceBody5AddressAndContacts {
                primary {
                  title1 {
                    html
                    text
                    raw
                  }
                  address {
                    html
                    text
                    raw
                  }
                  phone_number {
                    html
                    text
                    raw
                  }
                  email {
                    html
                    text
                    raw
                  }
                  button_label {
                    html
                    text
                    raw
                  }
                  button_link {
                    url
                    type
                    link_type
                  }
                  map {
                    html
                    text
                    raw
                  }
                  linkedin {
                    url
                    link_type
                    type
                  }
                  instagram {
                    url
                    link_type
                    type
                  }
                  facebook {
                    url
                    link_type
                    type
                  }
                  twitter {
                    url
                    link_type
                    type
                  }
                  tiktok {
                    url
                    link_type
                    type
                  }
                }
              }
            }
            body6 {
              ... on PrismicLocationSpaceBody6CheckAvailability {
                primary {
                  title1 {
                    html
                    text
                    raw
                  }
                  description1 {
                    html
                    text
                    raw
                  }
                  button_link {
                    url
                    link_type
                    type
                  }
                  button_label {
                    text
                  }
                  booking_widget
                  time_open_booking
                  time_close_booking
                }
              }
            }
            body7 {
              ... on PrismicLocationSpaceBody7Partner {
                primary {
                  title1 {
                    html
                    text
                    raw
                  }
                  description1 {
                    html
                    text
                    raw
                  }
                }
                items {
                  partner {
                    document {
                      ... on PrismicPartners {
                        data {
                          logo {
                            alt
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            body {
              ... on PrismicLocationSpaceBodyQuestions {
                primary {
                  title {
                    html
                    text
                    raw
                  }
                }
                items {
                  question {
                    html
                    text
                    raw
                  }
                  answer {
                    html
                    text
                    raw
                  }
                }
              }
            }
            body8 {
              ... on PrismicLocationSpaceBody8GeneralCard {
                primary {
                  title {
                    html
                    text
                    raw
                  }
                  robot {
                    html
                    text
                    raw
                  }
                  image {
                    url
                    alt
                  }
                  googlebot {
                    html
                    text
                    raw
                  }
                  favicon {
                    alt
                    url
                  }
                  description {
                    html
                    text
                    raw
                  }
                  bingbot {
                    html
                    text
                    raw
                  }
                }
                slice_type
              }
              ... on PrismicLocationSpaceBody8OpenGraph {
                primary {
                  og_url {
                    url
                    type
                    link_type
                  }
                  og_type {
                    html
                    text
                    raw
                  }
                  og_title {
                    html
                    text
                    raw
                  }
                  og_site_name {
                    html
                    text
                    raw
                  }
                  og_locale {
                    html
                    text
                    raw
                  }
                  og_image_width {
                    html
                    text
                    raw
                  }
                  og_image_height {
                    html
                    text
                    raw
                  }
                  og_image {
                    alt
                    url
                  }
                  og_description {
                    html
                    text
                    raw
                  }
                }
                slice_type
              }
              ... on PrismicLocationSpaceBody8TwitterCard {
                primary {
                  title {
                    html
                    text
                    raw
                  }
                  image {
                    alt
                    url
                  }
                  description {
                    html
                    text
                    raw
                  }
                }
                slice_type
              }
              ... on PrismicLocationSpaceBody8PinterestProductPin {
                slice_type
                primary {
                  title {
                    html
                    text
                    raw
                  }
                  description {
                    html
                    text
                    raw
                  }
                  price
                  currency
                  availability
                }
              }
            }
            body10 {
              ... on PrismicLocationSpaceBody10WhatsappWidget {
                primary {
                  phone_number {
                    html
                    text
                    raw
                  }
                  name {
                    html
                    text
                    raw
                  }
                  avatar {
                    url
                    alt
                  }
                }
              }
            }
          }
        },
      allEventbriteEvent(sort: {fields: start___utc, order: DESC}, limit: 3) {
        edges {
          node {
          summary
          url
          organizer_id
          logo {
            original { 
              url
            }
          }
          start {
            timezone
            local(formatString: "DD MMM YYYY")
            utc
          }
        }
      }
      totalCount
    }
  }
      `
