import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import PropTypes from "prop-types";
import { color, typography } from "../../shared/style";
import house from "../../images/house.svg"
export const Information = ({ data}) => {
  if(!data?.primary) return null;
  const {country, city, address, tags} = data?.primary
  return (
    <Wrapper>
      <WrapperDetail>
        <Detail>
          <Name>
            <CityName
              tag="h4"
              capital="uppercase"
              color={color.primary.blue}
              weight={typography.weight.regular1}
              font="Alternate Gothic No1 D"
            >
              {city}
            </CityName>
            <CountryName
              tag="h5"
              font="Alternate Gothic No1 D"
              color={color.netraul.black60}
              capital="uppercase"
              weight={typography.weight.regular1}
            >
              {" " +country}
            </CountryName>
          </Name>
          <Address>
            <Icon src={house} />
            <Text font="Oxygen" color={color.netraul.black80}>
              {address}
            </Text>
          </Address>
        </Detail>
        <TagList>
          {tags?.map((item, i) => (
            <Tag key={i}>{item.tag_name}</Tag>
          ))}
        </TagList>
      </WrapperDetail>
    </Wrapper>
  );
};
const Icon = styled.img`
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
  margin-right: 5px;
`;
const Wrapper = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  margin-top: 130px;
  order: -1;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    padding: 0 40px;
  }
  @media (max-width: 650px) {
    margin-top: 0;
    order: 0;
    padding: 20px;
    padding-top: 24px;
  }
`;
const TagList = styled.div`
  margin-top: 23px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-end;
`;
const WrapperDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 33px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    margin-bottom: 0;
  }
`;
const Detail = styled.div``;
const Name = styled.div``;
const CityName = styled(Text)`
  letter-spacing: -0.015em;
  font-size: 90px!important;
  line-height: 90px!important;
  @media (max-width: 650px) {
    font-size: 62px!important;
    line-height: 62px!important;
  }
`;
const CountryName = styled(Text)`
  letter-spacing: -0.015em;
  font-size: 36px!important;
  line-height: 36px!important;
  @media (max-width: 650px) {
    font-size: 30px!important;
    line-height: 36px!important;
  }
`;
const Tag = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  width: fit-content;
  background-color: ${color.primary.blue10};
  color: ${color.primary.blue};
  border-radius: 20px;
  padding: 5px 14px;
  margin-right: 10px;
  font-size: 12px;
  line-height: 22px;
  font-family: "Oxygen";
  cursor: default;
`;
const Address = styled.div`
  display: flex;
  @media (max-width: 650px) {
    align-items: flex-start;
  }
  @media (max-width: 1000px) {
    align-items: flex-start;
    padding-top: 14px;
  }
`;
Information.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        tag: PropTypes.string,
      })
    ),
    primary: PropTypes.shape({
      address: PropTypes.shape({
        text: PropTypes.string,
      }),
      country: PropTypes.shape({
        text: PropTypes.string,
      }),
      city: PropTypes.shape({
        text: PropTypes.string,
      }),
    }),
  }),
};
