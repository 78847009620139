import React, { useState } from 'react'
import styled from "styled-components";
import { color, typography } from "../../shared/style"
import { Text } from "../../components/Text/Text"
import PropTypes from "prop-types";
import { Button } from "../../components/Button/Button"
import htmlSerializer from "./RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import clock from "../../images/clock.svg"
const parseDate = (date) => new Date(date)?.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
export const Offer = ({ data }) => {
  const [tabIndex, setTab] = useState(0);
  const [showMore, setShowMore] = useState(false);
  if (!data?.items || !data?.primary) return null;
  const { primary, items } = data;
  return (
    <div id="whatWeOffer">
      <Container>
        <Wrapper>
          <Head>
            <Title
              tag="h3"
              capital="uppercase"
              weight={typography.weight.regular1}
              color={color.primary.blue}
            >
              <RichText
                render={primary?.title?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Title>
            <Decription
              lineHeight={26}
              font="Oxygen"
              color={color.netraul.black90}
            >
              <RichText
                render={primary?.description?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Decription>
          </Head>
          <Tabs>
            {items &&
              items.map((item, i) => (
                <Tab
                  key={i}
                  isActive={tabIndex === i}
                  onClick={() => setTab(i)}
                >
                  <TextExtend
                    tag="h5"
                    capital="uppercase"
                    weight={typography.weight.regular1}
                    color={
                      tabIndex === i ? color.primary.blue : color.primary.blue40
                    }
                  >
                    {item?.title}
                  </TextExtend>
                </Tab>
              ))}
          </Tabs>
          <Content>
            <IMG src={items[tabIndex]?.image.url} />
            {items[tabIndex]?.button_label?.text && (
              <ButtonExtend
                isMobile={true}
                to={items[tabIndex]?.button_link.url}
              >
                {items[tabIndex]?.button_label?.text}
              </ButtonExtend>
            )}
            <TextContent>
              <Text
                font="Oxygen"
                lineHeight={26}
                weight={typography.weight.bold}
                size={14}
                color={color.primary.blue}
              >
                <WrapperItem>
                  <Icon src={clock} />
                  {items[tabIndex]?.start_date_of_week} -{" "}
                  {items[tabIndex]?.end_date_of_week} <Div>| </Div>{" "}
                  {parseDate(items[tabIndex]?.start_time)} –{" "}
                  {parseDate(items[tabIndex]?.end_time)} CT
                </WrapperItem>
              </Text>
              <TextDescription
                tag="p"
                font="Oxygen"
                isMore={showMore}
                lineHeight={26}
                color={color.netraul.black90}
              >
                <RichText
                  render={items[tabIndex]?.content?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </TextDescription>
              {!showMore && (
                <Btn onClick={() => setShowMore(true)}>See more</Btn>
              )}
              {items[tabIndex]?.button_label?.text && (
                <ButtonExtend to={items[tabIndex]?.button_link.url}>
                  {items[tabIndex]?.button_label?.text}
                </ButtonExtend>
              )}
            </TextContent>
          </Content>
        </Wrapper>
      </Container>
    </div>
  );
};
const WrapperItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Btn = styled.div`
  display: none;
  @media (max-width: 650px) {
    font-family: Oxygen;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    margin-top: 24px;
    color: ${color.primary.blue};
    text-decoration: underline;
    text-underline-offset: 5px;
    display: block;
  }
`;
const TextDescription = styled(Text)`
  letter-spacing: 0.015em;
  @media (max-width: 650px) {
    margin-top: 32px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    ${(props) =>
      props.isMore &&
      `
      overflow: unset;
      display: -webkit-box;
      -webkit-line-clamp: inherit;
      -webkit-box-orient: vertical;
    `}
  }
`;
const Div = styled.span`
  font-weight: ${typography.weight.normal};
  margin-bottom: 0 !important;
`;
const ButtonExtend = styled(Button)`
  ${(props) =>
    props.isMobile
      ? `
      @media (max-width: 650px) {
        margin-bottom: 23px;
        display: block;
        width: 100%;
        padding: 19px 45px;
        border-radius: 40px;
      }
      display: none;
    `
      : `
      @media (max-width: 650px) {
        display: none;
      }
      display: block;
    `}
  float: right;
  border-radius: 40px;
  span {
    margin-bottom: 0 !important;
  }
`;
const Icon = styled.img`
  margin-right: 12px;
  max-width: 16px;
  max-height: 16px;
  width: 100%;
  height: 100%;
`;
const IMG = styled.img`
  width: calc(100% / 3);
  max-height: 422px;
  transition: all 0.6s linear;
  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const TextContent = styled(Text)`
  p,
  span {
    /* margin-bottom: 20px; */
  }
  flex: 1;
  margin-left: 30px;
  column-count: 2;
  column-gap: 30px;
  @media (max-width: 900px) {
    column-count: 0 !important;
  }
  @media (max-width: 650px) {
    column-count: 1;
    margin: 0;
  }
`;
const Container = styled.div`
  width: 100%;
  min-height: 500px;
  background-color: ${color.primary.blue10};
  padding-top: 100px;
  padding-bottom: 150px;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    box-sizing: border-box;
    padding: 40px;
    padding-top: 70px !important;
  }
  @media (max-width: 650px) {
    padding: 20px;
    padding-top: 70px !important;
  }
`;
const Wrapper = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
`
const Head = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: left;
    margin-bottom: 40px;
  }
`
const TextExtend = styled(Text)`
  cursor: pointer;
  transition: 0.3s;
  @media (max-width: 650px){
    font-size: 30px !important;
    line-height: 36px !important;
    font-weight: ${typography.weight.regular1};
  }
  `
const Title = styled(Text)`
  width: fit-content;
  padding-right: 8%;
  letter-spacing: -0.015em;
  font-size: 90px !important;
  line-height: 90px;
  @media (max-width: 650px) {
    line-height: 62px;
    font-size: 62px !important;
    margin-bottom: 20px;
  }

`
const Decription = styled(Text)`
  width: 360px;
  @media (max-width: 650px) {
    width: 100%;
  }
`
const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  flex-wrap:wrap;
  border-bottom: 4px solid ${color.primary.blue20};
  box-sizing: border-box;
`
const Tab = styled.div`
  position: relative;
  padding: 0 20px 16px 20px;
  &::after {
    transition: 0s;
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 4px;
    width: 0;
    ${props => props.isActive && `
      width: 100%;
      transition: 0.3s;
    `};
    background-color: ${color.primary.blue80};
  }
  @media (max-width: 650px) {
  padding: 0 10px 16px 10px;
  }
`
const Content = styled.div`
  margin-top: 42px;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`

Offer.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        button_label: PropTypes.shape({ text: PropTypes.string, }),
        button_link: PropTypes.shape({ url: PropTypes.string, }),
        content: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
        image: PropTypes.shape({
          url: PropTypes.string
        }),
        title: PropTypes.shape({
          text: PropTypes.string
        }),
        end_time: PropTypes.string,
        end_date_of_week: PropTypes.string,
        start_date_of_week: PropTypes.string,
        start_time: PropTypes.string,
      })
    ),
    primary: PropTypes.shape({
      title: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
      description: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};
