import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { color, typography } from '../../shared/style';
import { Text } from '../../components/Text/Text';
import { Button } from '../../components/Button/Button';
import Modal from '../../components/Modal/modal';
import calender from '../../images/calender.svg';
import { addDays } from 'date-fns';
import useApp from '../../contexts/context';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import PropTypes from "prop-types";
import { DateRangePickerComponent } from '../../components/DateRangePicker/DateRangePicker';
import { navigate } from "gatsby"

export const CheckAvailability = ({ isDescrition, data, selectedDate, setSelectedDate, email }) => {
    const { open, handleOpenModal } = useApp();
    const [timeline, setTimeline] = useState({ open: 0, close: 6 });
    const { time_open_booking, time_close_booking } = data?.primary || {};
    const currentDay = new Date().getDay();
    const onSelectDate = () => {
        handleOpenModal()
    }
    const redirectPage = ({ url, type, link_type }) => {
        if (link_type === "Web") {
            // #checkin=${addDays(selectedDate[0].startDate, 1).toISOString().substring(0, 10)}&checkout=${addDays(selectedDate[0].endDate, 1).toISOString().substring(0, 10)}
            typeof window !== "undefined" && window.open(`${url}`, '_blank')
        }
        if (link_type === "Document") {
            link_type === "homepage" ? navigate(`/`) : navigate(`/${type}`)
        }
        else typeof window !== "undefined" && (window.location.href = "#")
    }
    useEffect(() => {
        if (time_open_booking !== null && time_close_booking !== null) {
            setTimeline({
                ...timeline,
                open: time_open_booking === "Monday" ? 0 : time_open_booking === "Tuesday" ? 1 : time_open_booking === "Wednesday" ? 2 : time_open_booking === "Thursday" ? 3 : time_open_booking === "Friday" ? 4 : time_open_booking === "Saturday" ? 5 : 6,
                close: time_close_booking === "Monday" ? 0 : time_close_booking === "Tuesday" ? 1 : time_close_booking === "Wednesday" ? 2 : time_close_booking === "Thursday" ? 3 : time_close_booking === "Friday" ? 4 : time_close_booking === "Saturday" ? 5 : 6
            })
        }
    }, [time_open_booking, time_close_booking]);
    if (!data?.primary) return null;
    return (
        <Wrapper isDescrition={isDescrition}>
            <Div isDescrition={isDescrition}>
                <WrapperModal>
                    <Modal open={open} handleOpenModal={handleOpenModal}>
                        <MainContent>
                            <WrapperCalendar>
                                <DateRangePickerComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                                <ButtonExtendClose type="primary" onClick={() => onSelectDate()}>OK</ButtonExtendClose>
                            </WrapperCalendar>
                        </MainContent>
                    </Modal>
                </WrapperModal>
                <WrapperText>
                    <TextExtend tag="h4" lineHeight={56} capital="uppercase" color={isDescrition ? color.primary.white : color.primary.blue}>{data?.primary?.title1?.text}</TextExtend>
                </WrapperText>
                <Group isDescrition={isDescrition}>
                    {data?.primary?.booking_widget !== false && (
                        <>
                            <CheckInDate booking_widget={(currentDay >= timeline.open && currentDay <= timeline.close)} icon={calender} onClick={() => { handleOpenModal() }} type="outline_primary">
                                {selectedDate[0]?.startDate !== null ? addDays(selectedDate[0].startDate, 1)?.toISOString()?.substring(0, 10) : "Check in"}
                            </CheckInDate>
                            <CheckOutDate booking_widget={(currentDay >= timeline.open && currentDay <= timeline.close)} icon={calender} onClick={() => { handleOpenModal() }} type="outline_primary">
                                {selectedDate[0]?.endDate !== null ? addDays(selectedDate[0].endDate, 1)?.toISOString()?.substring(0, 10) : "Check out"}
                            </CheckOutDate>
                        </>
                    )}
                    <ButtonExtend
                            isDescrition={isDescrition}
                            type="primary"
                            onClick={() =>
                                redirectPage({
                                    url: data?.primary?.button_link?.url,
                                    type: data?.primary?.button_link?.type,
                                    link_type: data?.primary?.button_link?.link_type
                                })}
                        >{data?.primary?.button_label?.text}</ButtonExtend>
                    {/* {data?.primary?.button_link?.link_type !== "Any" ? (
                        <ButtonExtend
                            isDescrition={isDescrition}
                            type="primary"
                            onClick={() =>
                                redirectPage({
                                    url: data?.primary?.button_link?.url,
                                    type: data?.primary?.button_link?.type,
                                    link_type: data?.primary?.button_link?.link_type
                                })}
                        >{data?.primary?.button_label?.text}</ButtonExtend>
                    ) : (
                        <ButtonExtendMail href={`mailto: ${email}`}>{data?.primary?.button_label?.text}</ButtonExtendMail>
                    )} */}
                </Group>
            </Div>
        </Wrapper>)
}

const WrapperModal = styled.div`
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 101;
  /* @media (max-width: 649px) {
    display: none;
  } */
`;

// const Close = styled.div`
//     cursor: pointer;
//     position: absolute;
//     width: 20px;
//     top: 10px;
//     right: 10px;
// `

const MainContent = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  position: absolute;
  z-index: 100;
  background-color: white;
  padding: 40px;
`;
const WrapperCalendar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`
const Group = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 1000px) {
        flex-direction: column;
        width: 50%;
        padding: 20px;
        box-sizing: border-box;
    }
    @media (max-width: 650px) {
        width: 100%;
        box-sizing: border-box;
        padding: 30px 20px 40px 20px;
    }
`
const Div = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1200px) {
        justify-content: space-around;
    }
    @media (max-width: 1000px) {
        padding: 42px 40px;
    }
    @media (max-width: 650px) {
        flex-direction: column;
        padding: 40px 20px;
        padding-top: 30px;
        ${props => props.isDescrition && `margin-bottom: 70px;`}
    }
    padding: ${props => props.isDescrition ? '65px 125px 65px 125px' : '42px 40px'};

    background-color: ${props => props.isDescrition ? color.secondary.danube : color.secondary.bgrBlue};
`
const Wrapper = styled.div`
    box-sizing: border-box;
    max-width: ${props => props.isDescrition ? '1500px' : '1240px'};
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    /* margin-bottom: 30px; */
    @media (max-width: 1300px) {
        padding: ${props => !props.isDescrition ? '0 40px' : '0'};
    }
    @media (max-width: 650px) {
        padding: 20px;
    }
`
const WrapperText = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 200px;
    box-sizing: border-box;
    width: 35%;
    @media (max-width: 1000px) {
        width: 50%;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 650px) {
        justify-content: left;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding: 0 20px;
    }
`

const TextExtend = styled(Text)`
    white-space: inherit;
    font-weight: ${typography.weight.regular1};
    font-size: 90px!important;
    line-height: 90px;
    @media (max-width: 1000px) {
        font-size: 62px !important;
        line-height: 62px;
        font-weight: ${typography.weight.regular1};
    }

`
const ButtonExtendClose = styled(Button)`
    border-radius: 30px;
    width: fit-content;
    @media (max-width: 1000px) {
        width: 60%;
    }
`

const ButtonExtend = styled(Button)`
    border-radius: 30px;
    padding: 19px 45px;
    width: fit-content;
    @media (max-width: 1000px) {
        width: 100%;
    }
    span {
        line-height: 26px;
    }
`
const ButtonExtendMail = styled.a`
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    height: 65px;
    background-color: ${color.primary.blue};
    display: flex;
    align-items: center;
    padding: 0 45px;
    border-radius: 40px;
    color: ${color.primary.white};
    border: 1px solid ${color.primary.blue};
    transition-duration: 0.5s;
    &:hover {
      color: ${color.primary.blue};
      background: ${color.primary.white};
      border: 1px solid ${color.primary.blue};
    }
    @media (max-width: 1000px) {
        width: 100%;
    }
    span {
        line-height: 26px;
    }
`
const CheckInDate = styled(Button)`
    display: ${props => props.booking_widget === false ? "none !important" : "block"};
    width: 220px;
    border-radius: 50px;
    padding: 19px 22px;
    color: ${color.netraul.black100};
    font-weight: ${typography.weight.regular};
    @media (max-width: 1000px) {
        width: 100%;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0 30px;
    span {
        width: fit-content;
        line-height: 26px;
    }
`
const CheckOutDate = styled(Button)`
    display: ${props => props.booking_widget === false ? "none !important" : "block"};
    width: 220px;
    span {
        width: fit-content;
        line-height: 26px;
    }
    padding: 19px 22px;
    font-weight: ${typography.weight.regular};
    @media (max-width: 1000px) {
        margin: 10px 0 30px 0; 
        width: 100%;
    }
    border-radius: 50px;
    margin: 0 20px 0 20px;
    color: ${color.netraul.black100};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0 30px;
`

CheckAvailability.propTypes = {
    data: PropTypes.shape({
        primary: PropTypes.shape({
            button_label: PropTypes.shape({
                text: PropTypes.string,
            }),
            button_link: PropTypes.shape({
                text: PropTypes.string,
            }),
            description: PropTypes.shape({
                text: PropTypes.string,
            }),
            title: PropTypes.shape({
                text: PropTypes.string,
                type: PropTypes.string
            }),
        }),
    }),
    isDescrition: PropTypes.bool
};

CheckAvailability.defaultProps = {};