import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../components/Modal/modal";
import Carousel from "react-gallery-carousel";
import PropTypes from "prop-types";
import "react-gallery-carousel/dist/index.css";
import { color } from "../../shared/style";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";

export const Gallery = ({ data }) => {
  const [open, handleModal] = useState(false);
  const [index, setIndex] = useState(0);
  if (!data?.items) return null;
  const { items } = data;
  const CarouselData = items.map((item) => {
    return { src: item?.image?.url };
  });
  const tempItems = items?.slice(0, 5);
  const onOpenModal = (i) => {
    handleModal(true);
    typeof i === "number" && setIndex(i);
  };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setIndex(next),
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={13} />;
    },
  };

  return (
    <Wrapper>
      <Slider {...settings} className="slider"
      >
        {CarouselData.map((image, i) => (
          <TestImage src={image?.src} key={i} />
          ))}
      </Slider>
      
      <Counter>
        {index + 1 < 10 ? 0 : ""}
        {index + 1}/
        {CarouselData.length < 10
          ? "0" + CarouselData.length
          : CarouselData.length}
      </Counter>
      <WrapperModal>
        <Modal open={open} handleOpenModal={() => handleModal(false)}>
          <MainContent>
            <Carousel
              images={CarouselData}
              hasSizeButton={false}
              hasMediaButton={false}
              hasIndexBoard={false}
              index={index}
            />
          </MainContent>
        </Modal>
      </WrapperModal>
      <Image
        src={tempItems[0]?.image.url}
        onClick={() => onOpenModal(0)}
        size={tempItems?.length}
      />
      <GroupSubImage size={items?.length}>
        {tempItems?.map(
          (item, i) =>
            i > 0 && (
              <WrapperSubImage
                key={i}
                size={tempItems?.length}
                onClick={() => onOpenModal(i)}
                index={i}
              >
                <SubImage src={item?.image.url} />
                <Overlay
                  isMore={
                    tempItems.length < items.length &&
                    tempItems.length - 1 === i
                  }
                >
                  <Length>+{items.length}</Length>
                  <Photos>Photos</Photos>
                </Overlay>
              </WrapperSubImage>
            )
        )}
      </GroupSubImage>
    </Wrapper>
  );
};
const Counter = styled.div`
  /* width: max-content !important; */
  height: max-content !important;
  color: white;
  position: absolute;
  bottom: 21px;
  right: 20px;
  padding: 4px 10px;
  font-size: 14px;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 27px;
  background-color: ${color.netraul.black80};
  @media (min-width: 651px) {
    display: none;
  }
`;
const WrapperModal = styled.div`
  @media (max-width: 650px) {
    display: none;
  }
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
`;
const MainContent = styled.div`
  top: 0;
  position: absolute;
  z-index: 100;
  width: 80%;
  height: 80%;
  margin-top: 3%;
  div {
    background: transparent;
  }
  div div:last-child ul {
    justify-content: center;
  }
`;
const Length = styled.div`
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  font-family: Oxygen;
`;
const Photos = styled.div`
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  font-family: Oxygen;
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  position: absolute;
  font-family: Oxygen;
  top: 0;
  left: 4.5px;
  right: 4.5px;
  bottom: 9px;
  /* width: 100%; */
  ${(props) => (props.isMore ? `opacity: 1;` : `opacity: 0;`)}
  display: flex;
  /* height: 100%; */
  color: white;
  text-align: center;
  flex-direction: column;
  justify-content: center;
`;
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  .slider {
    display: none;
    @media (max-width: 650px) {
      /* button {
       margin-bottom: 32px;
     } */
      position: relative;
      display: block;
      max-height: 244px;
      width: 100%;
    }
    .slick-list {
      max-height: 244px;
      div {
        max-height: 244px;
        height: 100%;
      }
    }
    .slick-dots {
      ul {
        /* transition: none; */
      }
      position: absolute !important;
      bottom: 24px;
      left: 45%;
      @media (max-width: 415px) {
        left: 43%;
      }
      li {
        width: 7px;
        margin: 0 3px;
        button {
          height: 7px;
          width: 7px;
          :before {
            color: #ffffff;
            opacity: 0.4;
            font-size: 7px;
            height: 7px;
            width: 7px;
          }
        }
      }
      li.slick-active button:before {
        opacity: 1;
      }
      li.small button:before {
        font-size: 3px;
      }
    }
  }
  @media (max-width: 1300px) {
    padding: 0 40px;
    box-sizing: border-box;
  }
  @media (max-width: 650px) {
    padding: 0;
    margin-top: 90px;
    order: -1;
  }
  order: 0;
  height: 100%;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  max-height: 534px;
  display: flex;
  flex-direction: row;
  .prev {
    background: rgba(0, 0, 0, 0.5);
    left: -100px;
    width: 26px;
    text-align: center;
    font-size: 26px;
    border-radius: 50%;
  }
  .next {
    background: rgba(0, 0, 0, 0.5);
    right: -100px;
    width: 26px;
    text-align: center;
    font-size: 26px;
    border-radius: 50%;
  }
`;
const TestImage = styled.img`
  height: 244px;
`;
const Image = styled.img`
  @media (max-width: 650px) {
    display: none;
  }
  width: ${(props) => (props.size === 1 ? "100%" : "60%")};
  padding: 0 0 9px 0;
  cursor: pointer;
`;
const WrapperSubImage = styled.div`
  padding: 0 4.5px 9px 4.5px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  ${(props) =>
    props.size === 2
      ? `
      width: 100%;
      height: 100%;
    `
      : props.size === 3
      ? `
      width: 100%;
      height: 50%;
    `
      : props.size === 4
      ? `
      width: ${props.index === 3 ? "100%" : "50%"};
      height: 50%;
    `
      : `
      width: 50%;
      height: 50%;
    `}
`;
const SubImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
const GroupSubImage = styled.div`
  padding-left: 9px;
  width: ${(props) => (props.size === 1 ? "0%" : "40%")};
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 650px) {
    display: none;
  }
`;

Gallery.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sub_image: PropTypes.shape({
          url: PropTypes.string,
        }),
      })
    ),
  }),
};
