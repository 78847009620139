import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { color, typography } from "../../shared/style";
import { Text } from "../../components/Text/Text";
import circle from "../../images/Ellipse.svg";
import htmlSerializer from "./RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";

const ItemList = ({ item }) => {
  return (
    <WrapperItem>
      {/* <Fade bottom cascade disabled={isMobile} distance="10px"> */}
      <TextExtend color={color.primary.white} font="Oxygen">
        {item?.guide?.text}
      </TextExtend>
      {/* </Fade> */}
    </WrapperItem>
  );
};

export const Why = ({ data }) => {
  if(!data?.items || !data?.primary) return null;
  const { items, primary } = data;
  return (
    <div id="why">
      <Wrapper>
        {/* <Fade bottom cascade duration={2000} disabled={isMobile} distance="10px"> */}
        <Contain>
          <Left>
            <Image src={primary?.image.url} alt={"why"} />
          </Left>
          <Right>
            <RightWrapper>
              <WrapperText>
                <Title
                  color={color.primary.white}
                  tag="h3"
                  capital="uppercase"
                  font="Alternate Gothic No1 D"
                  weight={typography.weight.regular1}
                >
                  <RichText
                    render={primary.title1.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </Title>
                <Description
                  color={color.primary.blue20}
                  font={typography.type.primary}
                >
                  {primary?.description1?.text}
                </Description>
              </WrapperText>
              <List>
                {items &&
                  items?.map((item, i) => <ItemList key={i} item={item} />)}
              </List>
            </RightWrapper>
          </Right>
        </Contain>
        {/* </Fade> */}
      </Wrapper>
    </div>
  );
};
const List = styled.ul`
  padding-left: 10px;
  list-style-image: url(${circle});
  list-style-position: outside;
  margin-bottom: 10px;
  @media (max-width: 650px) {
    padding-left: 20px;
  }
`;
const RightWrapper = styled.div`
  padding: 100px 90px 100px 100px;
  @media (max-width: 1000px) {
    padding: 40px;
  }
`;
const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 650px) {
    margin-bottom: 22px;
    padding: 0;
  }
`;
const TextExtend = styled(Text)`
`;
const WrapperItem = styled.li`
  /* padding: 24px; */
  margin-bottom: 12px;
  span {
    padding-left: 16px;
  }

`;
const Title = styled(Text)`
  line-height: 90px;
  @media (max-width: 1000px) {
    line-height: 62px!important;
  }
`;
const Left = styled.div`
  width: 50%;
  @media (max-width: 650px) {
    width: 100%;
    line-height: 0;
  }
`;
const Right = styled.div`
  width: 50%;
  background-color: ${color.primary.blue};
  .react-reveal {
    height: 100%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const Contain = styled.div`
  position: relative;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100%;
  flex-direction: row;
  @media (max-width: 1300px) {
    box-sizing: border-box;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const Wrapper = styled.div`
  .react-reveal {
    margin: 0 auto;
  }
  height: 100%;
  display: flex;
  flex-direction: row;
  /* margin: 0 auto; */
  flex-wrap: wrap;
  background: linear-gradient(to bottom, #e9ecf1 50%, #ffff 50%);
  margin-bottom: 150px !important;
  @media (max-width: 1300px) {
    padding: 0 40px;
    box-sizing: border-box;
  }
  @media (max-width: 650px) {
    margin-bottom: 60px !important;
    padding: 20px;
    background: linear-gradient(to bottom, #e9ecf1 50%, #ffff 50%);
    flex-direction: column;
  }
`;

const Description = styled(Text)`
  margin: 32px 0;
  @media (max-width: 650px) {
    margin: 24px 0 22px 0;
  }
`;

Why.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
        readmore: PropTypes.shape({
          url: PropTypes.string,
        }),
        date: PropTypes.string,
      })
    ),
    primary: PropTypes.shape({
      read_all_article: PropTypes.shape({
        url: PropTypes.string,
      }),
      title: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};
