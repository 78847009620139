import scrollTo from "gatsby-plugin-smoothscroll";
import React, {useState} from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { color, typography } from "../../shared/style";
import { About } from "./About";
import { Address } from "./Address";
import { CheckAvailability } from "./CheckAvailability";
import { Events } from "./Events";
import { FAQ } from "./FAQ";
import { Gallery } from "./Gallery";
import { Information } from "./Information";
import { Offer } from "./Offer";
import { Partners } from "./Partners";
import { Team } from "./Team";
import { Testimoials } from "./Testimoials";
import { Why } from "./Why";
import { addDays } from 'date-fns';
import FloatingWhatsApp from 'react-floating-whatsapp'
import Fundamentals from "./Fundamentals";


export const SpaceContainer = ({ data, upcomingEvent }) => {

  const list = [
    {
      id: "about",
      name: "About This location",
    },
    {
      id: "whatWeOffer",
      name: "What’s inside",
    },
    {
      id: "why",
      name: "Mini survival guide",
    },
    {
      id: "review",
      name: "What people say?",
    },
    {
      id: "events",
      name: "Upcoming events",
    },
    {
      id: "believe",
      name: "You can rely on us",
    },
    {
      id: "partner",
      name: "Partners",
    },
    {
      id: "whereAreWe",
      name: "Where are we?",
    },
    {
      id: "questions",
      name: "Questions",
    },
  ];
  const [selectedDate, setSelectedDate] = useState([{
    startDate: new Date(),
    endDate: addDays(new Date(), 1),
    key: 'selection'
  }]);
  const handleScroll = (value) => {
    if (document.getElementById(value)) {
      document.getElementById(value).classList.add("style");
      scrollTo("#" + value);
    }
  };
  // const location_events = useMemo(() => {
  //   // if(data.organizer.text) {
  //   //   return upcomingEvent
  //   // }
  // },[data.organizer.text])
  return (
    <WrapperLayout>
      {/* {data && data?.body?.map((item, i) => {
        return <RenderSpace key={i} type={item.slice_type} data={item} upcomingEvent={upcomingEvent} />
      })}
      {isCheck && <Div><CheckAvailability data={isCheck} isDescrition={true} /></Div>} */}
      <CheckMobile>
        <WrapperSection>
          {list.map((item, index) => (
            <ButtonNav key={index} onClick={() => handleScroll(item.id)}>
              {item.name}
            </ButtonNav>
          ))}
        </WrapperSection>
      </CheckMobile>
      <Information
        data={{
          primary: {
            country: data?.country?.text,
            city: data?.city?.text,
            tags: data?.tags,
            address: data.body5[0]?.primary?.address?.text,
          },
        }}
      />

      <Gallery data={{ items: data?.gallery }} />

      <CheckAvailability
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        data={data.body6[0] === undefined ? {} : data.body6[0]}
        email={data?.body5[0]?.primary?.email?.text}
      />
      <About
        data={{
          primary: {
            title: data?.title.text,
            description: data?.description.raw,
          },
          items: data.body2[0],
        }}
      />
      <Offer data={data?.body1[0] === undefined ? {} : data?.body1[0]} />
      <Why data={data?.body3[0] === undefined ? {} : data?.body3[0]} />
      {!!data?.fundamentals?.length && data?.fundamentals.map((item, index) => {
        return <Fundamentals dataFundamental={item} key={index}/>
      })}
      {data?.body4[0] !== undefined && data?.body4?.map((item, index) => {
        return <Testimoials key={index} data={item === undefined ? {} : item} /> 
      })}
      <Events data={upcomingEvent} subData={{buttonText: data?.event_button_label?.text, buttonLink: data?.event_button_link}} />
      <Team data={data?.body9[0] === undefined ? {} : data?.body9[0]} />
      <Partners data={data?.body7[0] === undefined ? {} : data?.body7[0]} />
      <Address
        data={{
          ...data?.body5[0],
          city: data?.city?.text,
          country: data?.country?.text,
        }}
      />
      <FAQ data={data?.body[0]} />
      <Div>
        <CheckAvailability
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          isDescrition={true}
          data={data?.body6[0]}
          email={data?.body5[0]?.primary?.email?.text}
        />
      </Div>
      {data?.body10[0] && (
        <FloatingWhatsAppCustom  
          phoneNumber={data?.body10[0]?.primary?.phone_number?.text}
          accountName={data?.body10[0]?.primary?.name?.text}
          avatar={data?.body10[0]?.primary?.avatar?.url}
          allowEsc
          notification
          notificationDelay={60000} // 1 minute
          notificationSound
          statusMessage="Draper startup house"
          height={400}
        />
      )}
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  .style {
    margin-top: -180px;
    padding-top: 180px;
  }
`;

const Div = styled.div`
  /* margin-bottom: -50px; */
`;
const CheckMobile = styled.div`
  display: none;
  position: sticky;
  top: 90px;
  left: 0;
  z-index: 9;
  @media (max-width: 650px) {
    display: block;
    width: 100%;
  }
`;
const WrapperSection = styled.div`
  display: flex;
  box-sizing: border-box;
  overflow-x: scroll;
  @media (max-width: 650px) {
    margin-bottom: 6px;
  }
`;
const ButtonNav = styled(Button)`
  display: flex;
  height: 80px;
  padding: 30px;
  justify-content: center;
  align-items: center;
  background: ${color.primary.white};
  border-top: none;
  border-radius: 0;
  border: 1px solid ${color.netraul.black10};
  box-shadow: 1px 0px 0px #d2d9e2;
  font-weight: ${typography.weight.bold};
  font-size: ${typography.size.s2}px;
  color: ${color.netraul.black70};
  line-height: 22px;
  box-sizing: border-box;
  &:hover {
    border-top: none;
  }
`;
const FloatingWhatsAppCustom = styled(FloatingWhatsApp)`
  div header,
  div footer {
    margin: 0;
  }
  div {
    z-index: 9999;
  }
`;
